<template>
  <div>
    <el-table :data="fpayForm.fpay_fee_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="出运发票号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.tran_no'">
            <el-input
              :value="helper.transverseTranNo(fpayForm.fpay_fee_list[scope.$index].tran_no)"
              disabled
              maxlength="30"
              show-word-limit
              placeholder="暂无出运发票号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="增值税发票号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.fpay_actu_nos'">
            <el-input
              @change="nosChnage"
              @input="
                fpayForm.fpay_fee_list[scope.$index].fpay_actu_nos = helper.keepTNum(fpayForm.fpay_fee_list[scope.$index].fpay_actu_nos)
              "
              v-model="fpayForm.fpay_fee_list[scope.$index].fpay_actu_nos"
              maxlength="30"
              show-word-limit
              placeholder="暂无增值税发票号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="报告编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.fpay_fee_repo'">
            <el-input
              v-model="fpayForm.fpay_fee_list[scope.$index].fpay_fee_repo"
              @input="
                fpayForm.fpay_fee_list[scope.$index].fpay_fee_repo = helper.keepTNum(fpayForm.fpay_fee_list[scope.$index].fpay_fee_repo)
              "
              maxlength="30"
              show-word-limit
              placeholder="暂无报告编号"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">客户简称</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.cust_abbr'" :rules="[{ required: true }]">
            <el-input
              disabled
              v-model="fpayForm.fpay_fee_list[scope.$index].cust_abbr"
              maxlength="20"
              show-word-limit
              placeholder="暂无客户简称"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="最终客户">
        <template slot="header">
          <span class="vg_deep_red">最终客户</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.cust_name'" :rules="[{ required: true }]">
            <!-- <el-input v-model="fpayForm.fpay_fee_list[scope.$index].cust_name" maxlength="10" show-word-limit
              placeholder="暂无最终客户"></el-input> -->
            <el-select
              disabled
              @change="custNameChange(scope.$index)"
              filterable
              v-model="fpayForm.fpay_fee_list[scope.$index].cust_name"
              placeholder="请选择"
            >
              <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_name" :value="item.cust_name"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">费用金额</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0px" :prop="'fpay_fee_list.' + scope.$index + '.fpay_fee_pay'" :rules="[{ required: true }]">
            <template slot="label">
              <span style="display: none">费用金额</span>
            </template>
            <el-input
              @change="nosChnage"
              v-model="fpayForm.fpay_fee_list[scope.$index].fpay_fee_pay"
              @input="val => (fpayForm.fpay_fee_list[scope.$index].fpay_fee_pay = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="暂无费用金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.fpay_remarks'" :rules="[{ required: false }]">
            <el-input
              v-model="fpayForm.fpay_fee_list[scope.$index].fpay_remarks"
              maxlength="255"
              show-word-limit
              placeholder="暂无备注信息"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <!-- <el-table-column label="产品描述" width="200">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'fpay_fee_list.' + scope.$index + '.prod_desc'">
            <el-input v-model="fpayForm.fpay_fee_list[scope.$index].prod_desc" disabled maxlength="20" show-word-limit
              placeholder="暂无产品描述">
              <template slot="append">
                  <el-popover
                    placement="bottom"
                    width="600"
                    @show="textEnlargementShow(0,scope.$index)"
                    trigger="click">
                      <textEnlargement ref="textEnlargement" :disabledFlag="true" @textEnlargementChange="textEnlargementChange" :textEnlargementString="textEnlargementString"></textEnlargement>
                    <span slot="reference"><el-link type="info">...</el-link></span>
                  </el-popover>
                </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { custAPI } from '@/api/modules/cust';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { keep2Decimal } from '@assets/js/regExUtil';

export default {
  name: 'CostDetailList',
  props: {
    fpayForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      multiSelection: [],
      custOptionList: []
    };
  },
  components: {
    // textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    initData() {
      this.initCust();
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].fpay_fee_pay ? datas[i].fpay_fee_pay : 0); //费用金额
        }
        totalList[7] = totalA.toFixed(2);
      });
      return totalList;
    },
    // 更改关联单位名称
    custNameChange(val) {
      for (let i = 0; i < this.custOptionList.length; i++) {
        if (this.custOptionList[i].cust_name === this.fpayForm.fpay_fee_list[val].cust_name) {
          this.fpayForm.fpay_fee_list[val].cust_abbr = this.custOptionList[i].cust_abbr;
        }
      }
    },
    // 单号回显主表
    nosChnage() {
      this.$emit('nosChnage');
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.fpayForm.fpay_fee_list[val1].prod_desc;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.fpayForm.fpay_fee_list[this.textEnlargementNum].prod_desc = val;
      }
    },
    // // 计算价格保留四位
    calcPrice(num, posi = 0, str) {
      let num1 = 0;
      let fre = null;
      if (posi % 3 === 1) {
        fre = Math.pow(10, posi + 1);
      } else {
        fre = Math.pow(10, posi);
      }
      num1 = (num * fre) / fre;
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.0001;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.0000';
            } else {
              if (value.length - 1 - ind > 4) {
                return value.substr(0, ind + 5);
              } else if (value.length - 1 - ind < 4) {
                let index = 4 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    },
    // 计算价格保留2位
    calcPriceT(num, posi = 0, str) {
      let num1 = 0;
      num1 = Number(num);
      if (isNaN(num1)) {
        return '';
      } else {
        if (num1 >= str) {
          return str - 0.01;
        } else {
          let value = num1;
          value = value.toString();
          if (value) {
            let ind = value.toString().indexOf('.');
            if (ind < 0) {
              return value + '.00';
            } else {
              if (value.length - 1 - ind > 2) {
                return value.substr(0, ind + 3);
              } else if (value.length - 1 - ind < 2) {
                let index = 2 - (value.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  value = value + '0';
                }
                return value;
              } else {
                return value;
              }
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
