import topic from '../topic';
export const fpayAPI = {
  getFpays: topic.fpayTopic + '/get_fpays',
  getFpayById: topic.fpayTopic + '/get_fpay_by_id',
  addFpay: topic.fpayTopic + '/add_fpay',
  editFpay: topic.fpayTopic + '/edit_fpay',
  deleteFpayByIds: topic.fpayTopic + '/delete_fpay_by_ids',
  editFpayPinvInvstat: topic.fpayTopic + '/edit_fpay_pinv_invstat',
  getFpayFeeByActuNos: topic.fpayTopic + '/getFpayFeeByActuNos'
};
